html {
  scroll-behavior: smooth;
}

.ibm-plex-sans-thin {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .ibm-plex-sans-extralight {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .ibm-plex-sans-light {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .ibm-plex-sans-regular {
    font-family: "IBM Plex Sans", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
  }
  
  .ibm-plex-sans-medium {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .ibm-plex-sans-semibold {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .ibm-plex-sans-bold {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .ibm-plex-sans-thin-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .ibm-plex-sans-extralight-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .ibm-plex-sans-light-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .ibm-plex-sans-regular-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .ibm-plex-sans-medium-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .ibm-plex-sans-semibold-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .ibm-plex-sans-bold-italic {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'swearText';
    src: url('./fonts/fonnts.com-Swear-Text-Light.otf') format('truetype'); /* or woff, woff2 */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'swearText-normal';
    src: url('./fonts/fonnts.com-Swear-Text-.otf') format('truetype'); /* or woff, woff2 */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'swearText-semibold';
    src: url('./fonts/fonnts.com-Swear-Text-Light.otf') format('truetype'); /* or woff, woff2 */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'swearText-italic';
    src: url('./fonts/fonnts.com-Swear-Text-Medium.otf') format('truetype'); /* or woff, woff2 */

  }

  .swear-text {
    font-family: "swearText", sans-serif;
  }

  .swear-text-normal {
    font-family: "swearText-normal", sans-serif;
  }

  .swear-text-semibold {
    font-family: "swearText-semibold", sans-serif;
  }

  .swear-text-italic {
    font-family: "swearText-italic", sans-serif;
  }
  